import React from "react"

const ChartMan = () => (
    <svg width="386" height="329" viewBox="0 0 386 329" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>Illustration of a man holding an arrow that is pointing up and to the right to show growth</desc>
        <path d="M170.8 152.4C171.8 155.7 172.8 158.7 173.7 161.3C174 162.3 174.3 163.2 174.6 164L149.2 170.3L170.8 152.4Z" fill="#61D0EC"/>
        <path d="M178.8 86.1C178.8 86.1 178.8 86 178.8 86.1L187.8 87.1C192.1 90.6 194.8 131 193.3 133.8L193.1 134L190.7 136L191.5 140.9L186.7 117.1L185.6 105C190.1 94 179.4 86.4 178.8 86.1Z" fill="white"/>
        <path d="M178.8 86.1C179.4 86.5 190.1 94 185.6 105L185.5 104.5C181.1 101.7 178.5 98 178.5 98C183.6 91.5 179.1 86.4 178.8 86.1Z" fill="#61D0EC"/>
        <path d="M181.2 170.1L186.4 169.9L198.4 169.5V169.8C198.7 183.7 197.9 195.3 197.2 195.8C196.4 195.9 195.5 196.1 194.7 196.2C187.3 197.4 180.4 198.1 174.2 198.6L174.1 197.9L180.4 170.4L180.5 169.9C180.8 170.1 181 170.1 181.2 170.1Z" fill="#61D0EC"/>
        <path d="M50 314.7C50 314.7 76.3 309 76.3 323.1L30.9 322.5C30.4 313.4 42.6 301.7 42.6 301.7C44.5 303.9 48.1 305.2 53.7 305.4V305.6C53.7 305.8 45.7 309.6 50 314.7Z" fill="white"/>
        <path d="M76.2999 323.2V327.7H69.8999H30.8999V322.6L76.2999 323.2Z" fill="#61D0EC"/>
        <path d="M254.7 313.4V317.4H248.4H209.9V312.9L254.7 313.4Z" fill="#61D0EC"/>
        <path d="M173.7 161.3C175.3 161.1 176.9 161 178.5 161.3C181.4 161.8 184.1 163.4 185.5 165.9C186.2 167.1 186.5 168.5 186.5 170L181.3 170.2C181.1 170.2 180.9 170.2 180.6 170.2C178.3 170 176.3 168.5 175.5 166.3C175.3 165.6 175 164.9 174.7 164.1C174.3 163.1 174 162.2 173.7 161.3Z" fill="white"/>
        <path d="M156 219.2L54.2 305.6C54 305.6 53.9 305.6 53.7 305.6C48.1 305.3 44.6 304 42.6 301.9C42.2 301.4 41.8 300.8 41.5 300.2C97.1 237.6 122.3 195 122.3 195L122.4 194.5C130.7 197.2 147.3 200.8 174.1 198.7C180.4 198.2 187.2 197.5 194.6 196.3L224.7 218C228.8 221 231.1 226 230.6 231L224.7 294.8L213.9 295.8L206.4 234.7L156 219.2Z" fill="white"/>
        <path d="M171.3 75.3C173.2 75.8 175.2 76.1 177.1 76C179.1 75.9 181 75.6 182.9 74.9L185.1 81.8C185 81.6 170.7 79 170.7 79L171.3 75.3Z" fill="white"/>
        <path d="M185.1 81.8L187.8 87L178.8 86L168.5 84.9L170.7 79C170.7 79 185 81.6 185.1 81.8Z" fill="white"/>
        <path d="M227.9 156.4L197.4 152.9L193.6 152.7L191.6 140.8L190.8 135.9L193.2 133.9C193.3 133.9 193.3 133.9 193.4 133.7L199.3 128.8L208.1 121.6L210.9 119.2L235.1 129.2L254.7 137.3L269.8 122.7L286.5 106.5L335.7 58.7L316.5 39.5L384.8 33.4L378.7 101.7L360.7 83.7L265.4 176.6L253.6 170.3L249.7 168.2L227.9 156.4Z" fill="#61D0EC"/>
        <path d="M254.7 313.4L209.9 312.9C209.4 304.4 214 296 214 296L224.8 295C224.8 295 222.7 300.6 226.7 305.4C226.7 305.3 254.7 299.3 254.7 313.4Z" fill="white"/>
        <path d="M268 111.2L266.5 122.5L266.6 123.1L234.9 129.3L210.7 119.3L207.9 121.7L198.7 118.1L199.2 129L193.3 133.9C194.8 131 192.1 90.7 187.8 87.2L227.7 110.5L268 111.2Z" fill="#61D0EC"/>
        <path d="M170.8 152.4L149.2 170.3L174.6 164C174.9 164.8 175.1 165.6 175.4 166.2C176.2 168.4 178.2 170 180.5 170.1L180.4 170.6L174.1 198.1L174.2 198.8C147.4 200.8 130.8 197.3 122.5 194.6C118 193.1 115.9 191.9 115.9 191.9C134.4 161.8 145.9 112.1 157.2 94.9C161 89.1 168.4 85.5 168.4 85.4L168.5 85L178.8 86.1C179.1 86.5 183.6 91.6 178.6 97.9C178.6 97.9 181.2 101.7 185.6 104.4L185.7 104.9L186.8 117L191.6 140.8L193.6 152.7L197.4 152.9L227.9 156.4L249.7 168.1L198.5 169.6L186.5 170C186.5 168.6 186.2 167.2 185.5 165.9C184.1 163.3 181.3 161.8 178.5 161.3C176.9 161 175.3 161.1 173.7 161.3C172.8 158.7 171.8 155.7 170.8 152.4Z" fill="#61D0EC"/>
        <path d="M269.5 122.5L266.7 123.1L266.6 122.5L268.1 111.2C268.1 111.2 272 107.4 277.4 105.5C282.8 103.6 286 106.1 286 106.1L286.5 106.6L269.8 122.8L269.5 122.5Z" fill="white"/>
        <path d="M185.9 42.1C185.7 41.1 186.3 40.2 187.2 39.9C188.2 39.7 189.1 40.3 189.4 41.2C189.6 42.2 189 43.1 188.1 43.4C187.1 43.6 186.1 43 185.9 42.1Z" fill="#272223"/>
        <path d="M188 43.4C189 43.2 189.6 42.2 189.3 41.2C189.1 40.2 188.1 39.6 187.1 39.9C186.1 40.1 185.5 41.1 185.8 42.1C186.1 43 187.1 43.6 188 43.4ZM149.4 66.7C147.1 64.5 145.1 61.9 143.4 59.2C141.8 56.6 140.1 53.6 139.3 50.6C138.4 46.7 140.2 43.4 143.8 41.7C143.2 38.8 144.1 35.6 146.2 34.1C149.4 31.9 153.1 30.8 156.7 29.3C159.7 28 162.3 26.3 164.6 24.1C167.5 21.3 169.9 18 172.1 14.5C173.7 11.9 175.3 9.3 177.3 7C179.6 4.4 182.7 2.1 186.2 1.8C189.2 1.5 192.6 2.6 194.2 5.3C197.4 10.5 193.2 16.5 189.6 20.2C188.3 21.6 186.8 22.9 185.4 24.2L185.5 24.4C190.3 30.3 193.9 37.1 196.3 44.3C196.8 45.8 197.1 47.3 197.3 48.8C197.8 53.4 197 58.2 195.5 62.6C193.3 68.8 188.5 73.1 182.8 75C180.9 75.6 179 76 177 76.1C175 76.2 173.1 75.9 171.2 75.4C170.4 75.2 169.7 74.9 169 74.6C167.7 74 166.4 73.3 165.1 72.4C164.9 73.8 163.9 74.9 161.7 74.4C157.1 73.1 152.8 70 149.4 66.7Z" fill="white"/>
        <path d="M180.2 171.5C180.2 171.7 173.7 199.1 173.7 199.1L189.8 197.5C189.8 197.5 191 175 190.7 172.6C190.4 170.2 188.5 169.6 188.5 169.6C188.5 169.6 180.7 169.4 180.2 171.5Z" fill="white"/>
        <path d="M194.7 196.3C195.5 196.2 196.4 196 197.2 195.9C198 195.4 198.7 183.8 198.4 169.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M168.5 84.9L170.7 79C170.7 79 185.1 81.6 185.1 81.8L187.8 87L178.8 86L168.5 84.9ZM168.5 84.9L168.4 85.3C168.4 85.4 160.9 89.1 157.2 94.8C146 112 134.5 161.7 115.9 191.8C115.9 191.8 118 193 122.5 194.5C130.8 197.2 147.4 200.8 174.2 198.7C180.5 198.2 187.3 197.5 194.7 196.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M190.7 135.9L191.5 140.9L193.6 152.7L197.3 152.9L227.8 156.4H227.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M180.4 170.6L174.1 198.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M165.1 72.3C166.3 73.2 167.6 74 169 74.5C169.7 74.8 170.5 75.1 171.2 75.3C173.1 75.8 175.1 76.1 177 76C179 75.9 180.9 75.6 182.8 74.9C188.4 73 193.3 68.7 195.5 62.5C197 58.1 197.9 53.4 197.3 48.7C197.1 47.2 196.8 45.7 196.3 44.2C194 37 190.4 30.2 185.5 24.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M163.2 67.5C164.6 68.5 165.4 70.7 165.2 72.3C165 73.7 164 74.8 161.8 74.3C157.1 73.2 152.9 70.1 149.5 66.8C147.2 64.6 145.2 62 143.5 59.3C141.9 56.7 140.2 53.7 139.4 50.7C138.5 46.8 140.3 43.5 143.9 41.8C143.3 38.9 144.2 35.7 146.3 34.2C149.5 32 153.2 30.9 156.8 29.4C159.8 28.1 162.4 26.4 164.7 24.2C167.6 21.4 170 18.1 172.2 14.6C173.8 12 175.4 9.4 177.4 7.1C179.7 4.5 182.8 2.19999 186.3 1.89999C189.3 1.59999 192.7 2.69999 194.3 5.39999C197.5 10.6 193.3 16.6 189.7 20.3C188.4 21.7 186.9 23 185.5 24.3C183.3 26.1 181 27.8 178.5 29.3C176.4 30.5 174.3 31.6 172.1 32.5C169.9 33.4 167.6 34.4 165.8 35.9C163.6 37.7 162.2 40.8 163.1 43.5C163.6 44.9 164.7 46 165.6 47.1C166.7 48.4 167.8 49.8 167.8 51.5C167.8 52.3 167.5 53.2 166.9 53.8C165.6 55 163.9 54.4 162.9 53.3C162.5 52.9 162.3 52.4 162 51.9C161.5 50.8 160.9 49.8 160.1 48.8L159.6 48.2C158.1 46.4 155.6 45.6 153.3 46.1L153 46.2C149.6 47 147.6 50.4 148.4 53.7L149.1 56.5C149.6 58.6 151.2 60.3 153.3 61L156.8 62.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M182 38.4C182.6 37 184 36 185.6 35.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M153.1 51.7C153.1 51.7 156.1 51.3 158.3 53.2C159.1 53.8 159.8 54.7 160.2 56" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M158.3 53.2C158.3 53.2 154.4 53.6 153.8 56.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M190.7 8.5C191.3 10.9 190.3 13.5 188.7 15.5C187.1 17.5 185 18.9 182.9 20.2" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M153.1 35.2C158.6 34 163.6 30.8 167.1 26.4C170.2 22.6 172.2 17.8 176.2 15" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M143.7 50.1C144.3 57.2 149 63.8 155.5 66.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M148.2 46.1C148.9 44.6 150.3 43.3 151.9 42.9C153.5 42.5 155.3 42.8 156.7 43.7" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M194.7 196.3L224.8 218C228.9 221 231.2 226 230.7 231L224.8 294.8L214 295.8L206.5 234.7L156 219.2L54.2 305.6C54 305.6 53.9 305.6 53.7 305.6C48.1 305.3 44.6 304 42.6 301.9C42.2 301.4 41.8 300.8 41.5 300.2C97.1 237.6 122.3 195 122.3 195" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M171.3 75.3L170.7 79" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M182.9 75L185.1 81.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M187.8 87.1C192.1 90.6 194.8 131 193.3 133.8C193.3 133.9 193.2 134 193.1 134" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M187.8 87.1L227.7 110.4L268 111.2C268 111.2 271.9 107.4 277.3 105.5C282.7 103.6 285.9 106.1 285.9 106.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M170.8 152.4L149.2 170.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M235 129.3L254.7 137.3L269.7 122.7L286.5 106.5L335.7 58.6" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M208 121.7L210.8 119.3L235 129.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M190.7 135.9L193.1 133.9L193.3 133.8L199.2 128.9L208 121.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.6 163.9L149.2 170.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M360.7 83.6L265.4 176.5L253.6 170.1L249.7 168.1L227.9 156.4" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M360.7 83.6L378.6 101.5L384.7 33.3L316.5 39.4L335.7 58.6" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M269.5 122.5L266.7 123L235 129.3" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M208 121.7L198.7 118L199.2 128.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M186.7 117.1L191.5 140.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M249.7 168.1L198.5 169.6L186.5 170L181.3 170.2C181.1 170.2 180.9 170.2 180.6 170.2C178.3 170 176.3 168.5 175.5 166.3C175.3 165.6 175 164.9 174.7 164.1C174.4 163.3 174.1 162.4 173.8 161.4C172.9 158.8 171.9 155.9 170.9 152.5C167 139.9 162.6 122.8 162 108.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M178.8 86.1C179.4 86.5 190.1 94 185.6 105" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M178.8 86C178.8 86 178.8 86.1 178.8 86C179.2 86.4 183.6 91.5 178.6 97.8C178.6 97.8 181.2 101.6 185.6 104.3L185.7 104.8L186.8 116.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M254.7 317.4V313.4" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M209.9 312.9V317.4H248.4" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M224.8 294.9C224.8 294.9 222.7 300.5 226.7 305.3C226.7 305.3 254.7 299.3 254.7 313.4L209.9 312.9" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M214 295.9C214 295.9 209.5 304.4 209.9 312.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M76.3 327.7V323.2" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.8999 322.6V327.7H69.8999" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M53.6999 305.8C53.6999 305.8 45.5999 309.6 49.9999 314.7C49.9999 314.7 76.2999 309 76.2999 323.1L30.8999 322.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M42.7001 301.8C42.7001 301.8 30.5001 313.4 31.0001 322.6" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M268 111.2L266.6 122.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M335.7 99.1L279.1 153.7" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M290.3 110.8L264.2 135.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M247.6 140.9L218.2 129.3" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M197.6 148.9L223.8 151.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M169.9 157.7L161.5 164" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M379 42.9L375.4 81" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M332.1 44.2L361.5 40.7" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.3 140.9C145.3 140.9 137.5 170.4 128.7 182" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M194.6 174C194.6 174 196.3 184.2 194.8 189.8" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M168.3 194.1C168.3 194.1 144.4 195.9 130.2 190.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M167.8 115.1C167.8 115.1 172.4 141.7 174.7 148.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M230.1 164.9L191.2 166.1" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M225.1 239.5L221.7 281.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M205.6 228.9L165.7 217" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M156 219.2L166.6 209.7" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M145.3 222L99.8999 260.5" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70.4999 273.6L50.8999 295.9" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M214.1 309.2C214.5 306.4 215.3 303.6 216.5 301" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M56.2 316.8C58.6 316.5 61.1 316.1 63.5 316.4C65.9 316.7 68.2999 317.7 69.7999 319.6" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M173.7 161.3C175.3 161.1 176.9 161 178.5 161.3C181.4 161.8 184.1 163.4 185.5 165.9C186.2 167.1 186.5 168.5 186.5 170" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M264 317.4H254.7H248.4" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M204.2 317.4H194.8" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M88.9999 327.7H76.2999H69.8999" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24 327.7H1.5" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M190.1 170C190.1 170 191.8 188.6 190.1 197.1" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M267.1 118.9L257.1 120.1" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M263.3 117.9C263.687 117.9 264 117.587 264 117.2C264 116.813 263.687 116.5 263.3 116.5C262.913 116.5 262.6 116.813 262.6 117.2C262.6 117.587 262.913 117.9 263.3 117.9Z" fill="#272223"/>
        <path d="M259.9 118.2C260.287 118.2 260.6 117.887 260.6 117.5C260.6 117.113 260.287 116.8 259.9 116.8C259.513 116.8 259.2 117.113 259.2 117.5C259.2 117.887 259.513 118.2 259.9 118.2Z" fill="#272223"/>
    </svg>
)

export default ChartMan